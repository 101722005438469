Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  // Customizable Area Start
  exports.validationApiContentType = "application/json";
  exports.validationApiMethodType = "GET";
  exports.exampleAPiEndPoint = "EXAMPLE_URL";
  exports.getAssessementMethod = "GET";
  exports.submitAssessementTestAPiMethod = "POST";
  exports.allAssessementTestApiContentType = "application/json";
  exports.getAllAssessementTestUrl = `bx_block_assessmenttest/assessments`;
  exports.apiSubmitAssessmentQuestions = `bx_block_assessmenttest/submit_que_answer`;
  exports.submitAssessementTestUrl = `bx_block_assessmenttest/submit_assessment?assessment_id=`;
  exports.textInputPlaceHolder = "Enter Text";
  exports.labelTitleText = "AssessmentTest";
  exports.labelBodyText = "AssessmentTest Body";
  exports.faqQuestion = "/faqs";
  exports.accountCreationTitlename = "Help us find you a Coach who matches your needs.";
  exports.accountsubTitleName = "Tell us about you in 3 simple steps to meet with your best fit coach";
  exports.executiveGeneralHeading = "Help us find you a Coach who matches your needs.";
  exports.executiveGeneralQuestion = "I am seeking coaching…"
  exports.executiveAssessmentGetApi = "/bx_block_admin/exec_general_info";
  exports.assessmentTitle = "It's all about you";
  exports.questionAssessmentEndpoint = "bx_block_surveys/surveys/executive_survey_questions"
    // Customizable Area End