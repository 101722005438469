export const imgPasswordInVisible = require("../assets/processpng.png");
export const imgIcon = require("../assets/imgIcon.jpeg");
export const profile = require("../assets/profile.jpeg");
export const frameImage = require("../assets/Image1.png");
export const faqImg = require("../assets/faq.svg");
export const emailImg = require("../assets/emailImg.svg");
export const profileIcon = require("../assets/groupUserNewimage.png");
export const backImg = require("../assets/backImg.png");
export const chackbox = require("../assets/chackbox.png");
export const blanckchackbox = require("../assets/blanckchackbox.png");
export const image_icon = require("../assets/imagebutton_ico.svg");
export const imagebutton_icon = require("../assets/imagebutton_icon.svg");
export const logout = require("../assets/logout.jpeg");
export const photoUpload = require("../assets/photoupload.png");
export const attacheSvg = require("../assets/attache.svg");
export const errorMessageIcon = require("../assets/errorMessageIcon.png");
export const plusRed = require("../assets/plusRed.svg");
export const plusGray = require("../assets/plusGray.svg");
export const checkBox = require("../assets/checkBox.svg");
export const fileIcon = require("../assets/fileIcon.svg");
export const SideImage = require("../assets/sideImg.jpeg");
export const contactBackgroundImage = require("../assets/contactBackgroundImage.png");
export const home = require("../assets/home.svg");
export const coachyImage = require("../assets/image-COACHYLOGO.png");
export const sideImage2 = require("../assets/sideImage2.svg");
export const sideImage3 = require("../assets/sideImage3.svg");
export const sideImage4 = require("../assets/sideImage4.svg");
export const sideImage5 = require("../assets/sideImage5.svg");
export const sideImage6 = require("../assets/sideImage6.svg");
export const sideImage7 = require("../assets/sideImage7.svg");
export const sideImage8 = require("../assets/sideImage8.svg");
export const sideImage9 = require("../assets/sideImage9.svg");
export const sideImage10 = require("../assets/sideImage10.svg");
export const contactus = require("../assets/contactus.svg");
export const exitLogout = require("../assets/exitLogout.png");
export const addIcon = require("../assets/image_.png");
export const error = require("../assets/error.png");
export const deleteIcon = require("../assets/delete_outline_24px.png")
export const errorIcon = require("../assets/errors.png")
export const calendarImg = require("../assets/calendar.svg")
export const launchIcon = require("../assets/launch.svg")
export const recommendedImg=require("../assets/image_coach.png")
export const group=require("../assets/group_.png")

export const first = require("../assets/first.png");
export const second = require("../assets/firstN.png");
export const image1 = require("../assets/secondN.png");
export const image2 = require("../assets/thirdN.png");
export const image3 = require("../assets/fourN.png");
export const image4 = require("../assets/fifthN.png");
export const image5 = require("../assets/sixN.png");
export const image6 = require("../assets/sevenN.png");
export const image7 = require("../assets/eightN.png");
export const image8 = require("../assets/tenN.png");
export const image9 = require("../assets/nineN.png");
export const exitLoogout = require("../assets/exitLogoutt.svg");
export const logo = require("../assets/image_Logo.png");

export const plus = require("../assets/plus.svg");
export const minus = require("../assets/minus.svg");
export const dropDown = require("../assets/dropdown_icon.svg");
export const leftChevronImage = require("../assets/left_chevron.svg");
export const checkCheckBox = require("../assets/check_checkbox.svg");
export const unCheckCheckBox = require("../assets/uncheck_checkbox.svg");
export const redirectArrow = require("../assets/redirect_arrow.svg");