Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelTextBack = "Back";
exports.labelTextAvailable = "Available";
exports.labelScheduleSession = "Schedule Session";
exports.labelTextBookSession = "Book Session";
exports.labelTextClientName = "Client Name";
exports.labelTextSend = "Send";
exports.labelContactCoach = "Contact Coach";
exports.textReminderError = "You can enter maximum 500 characters";
exports.labelBodyText = "scheduling Body";
exports.sessionErrorCoachMsg = "Selected client doesn't have active package.";
exports.sessionErrorMessage = "There is no active package to book a session.";
exports.labelReminder = "A reminder will be sent to your coach.";
exports.labelReminderInfo = "Would you like to add any additional information. Please write it below.";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";
exports.getProfileEndPoint = "/account_block/accounts/get_profile";
exports.createSessionNotesEndPoint= "bx_block_chat/session_notes/create_chemistry_session_notes";
exports.getBookingSessionEndPoint= "bx_block_scheduling/chemistry_sessions/upcoming_sessions";
exports.getReviewsRating= "bx_block_reviews/client_ratings/view_rating?coach_id=";
exports.getClientListForSessionEndPoint="bx_block_chat/coach_sessions/client_listing";







// KP Code 
// exports.calanldyApiToken=process.env.REACT_APP_CALANDLY_API_TOKEN
exports.apiToken=process.env.REACT_APP_CALANDLY_API_TOKEN
exports.contentType="application/json"
exports.methodTypeGet="GET"
exports.methodTypePost="POST"
exports.sentSessionDetailsWithClientEndPoint="bx_block_chat/coach_sessions"

// exports.apiToken = "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjk1NTQ4MDk5LCJqdGkiOiI5NTJjMjFjYy0zOGU0LTRlYjAtYjJlYS05ZTcxNWE4OTkzMGYiLCJ1c2VyX3V1aWQiOiIxZDUwMDFiZC0zMjJiLTRkNjEtYjM4Zi04YjM1ZDM2MzA3MDkifQ.nn--Bp1Gb-Z-J_Z5vlDW3GEfd0DvGuVzBW8HM1V2Ic3VsMW-fMtpCQjSfwtEjQQ-sblhC84LMlpWadH2Jefvrw"
exports.postEventAndInviteeDetailsEndPint = "bx_block_scheduling/chemistry_sessions";
exports.sessionDetailsEndPoint = "bx_block_chat/coach_sessions";
exports.coachSessionListEndPoint = "bx_block_scheduling/chemistry_sessions/upcoming_sessions";
exports.coachContactApiEndPoint = "bx_block_scheduling/chemistry_sessions/contact_coach";
exports.coachNameListEndPoint = "bx_block_chat/coach_sessions/coach_listing";
exports.clientRecentSessionEndPoint = "bx_block_scheduling/chemistry_sessions/client_recent_sessions";
exports.clientSessionNotesEndPoint="bx_block_scheduling/chemistry_sessions/get_session_notes"
exports.sessionDetailsEnsPoint = "bx_block_scheduling/chemistry_sessions";
exports.coachDetailsText = "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Perferendis corporis repudiandae, laborum, molestiae ratione, vel laudantium quam suscipit vitae veniam temporibus animi! Voluptas corporis eum necessitatibus quasi, delectus ex obcaecati? lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum asperiores doloribus qui consectetur, fugiat dicta aliquam voluptas maiores minima, atque saepe corrupti, illum quae perspiciatis ea ex corporis? Iure totam eum rem sapiente non quos nihil quidem cum.";
exports.coachAssessment = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto libero provident suscipit, impedit amet magni corporis quia, similique quidem a ducimus eveniet harum vitae, odio necessitatibus expedita inventore repudiandae. Natus!";
exports.coachSubHeading = "Would you like to add any additional information. Please write it below.";
exports.getCoachDetailsEndPoint = "account_block/accounts";
exports.showText = "coach_show";
exports.qualification = "Qualification, experience & Assessments";
exports.noRecentSessions = "No Recent Sessions";
exports.noUpcomingSessions = "No Upcoming Sessions";
exports.updateSessions = "bx_block_scheduling/chemistry_sessions";
exports.apiType = "formData";
exports.putMethod = "PUT";
exports.recentSessionsEndPoint = "bx_block_scheduling/chemistry_sessions/client_recent_sessions";
exports.cancleQuestion = "Are you sure you want to  cancel the session?";
exports.checkedFirst = "I accept and have read the";
exports.checkedSescond = "Cancellation policy";
exports.firstPolicy = " You may cancel your chemistry call at any time. However, to ensure the best experience for both you and our coaches, we ask that you please cancel at least 24 hours in advance. This allows us to offer the time slot to another client who may be eager to connect with a coach.";
exports.secondPolicy = "Please cancel any individual coaching sessions you cannot attend at least 24 hours in advance. This allows your coach to plan their schedule effectively and offer the time to other clients.";
exports.cancelSessions = "bx_block_scheduling/chemistry_sessions/cancel_chemistry_session";
exports.getDashboardHomeDetails="/bx_block_dashboard/coach_dashboard";
exports.reviewMessage = "Highly recommended dolor sit amet, consectetur adipiscing elit. Tellus a, dignissim vitae vehicula cursus viverra non facilisis. Id nunc fringilla vestibulum.";
// Customizable Area End