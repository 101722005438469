import React, { useEffect, useState } from "react";
import {
    styled,
    Modal,
    Typography,
    Grid,
    Box
} from "@material-ui/core";
import { removeStorageData } from "framework/src/Utilities";

interface Props {
    showLogoutModal: boolean;
    logoutModalClose: () => void;
    logoutUser: () => void;
}

function LogoutModal({ showLogoutModal, logoutModalClose, logoutUser }: Props) {
    const logoutUserData = async () => {
       removeStorageData("activeStepper");
       removeStorageData("coachID");
      logoutUser();
      localStorage.clear();
    };

    const [userType,setUserType]=useState("");

    useEffect(()=>{
        const user = localStorage.getItem('userType');
        if(user){
        setUserType(user);}
    },[]);

  
    return (
        <Modal
            open={showLogoutModal}
            onClose={logoutModalClose}
        >
            <ModalBody>
                <Typography className="modalTitle">
                    Logout
                </Typography>
                <Grid container spacing={3} className="exitLogoutStyle" justifyContent="center">
                <Grid>
                    <StyledImgBox>
                        <svg
                        width="100"
                        height="100"
                        viewBox="0 0 100 100"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            d="M86.8752 48.5404L69.1668 30.832C68.3335 29.9987 67.0835 29.9987 66.2502 30.832C65.4168 31.6654 65.4168 32.9154 66.2502 33.7487L80.4168 47.9154H39.7918C38.5418 47.9154 37.7085 48.7487 37.7085 49.9987C37.7085 51.2487 38.5418 52.082 39.7918 52.082H80.2085L66.0418 66.2487C65.2085 67.082 65.2085 68.332 66.0418 69.1654C66.4585 69.582 67.0835 69.7904 67.5002 69.7904C67.9168 69.7904 68.5418 69.582 68.9585 69.1654L86.6668 51.457C87.0835 51.0404 87.2918 50.6237 87.2918 49.9987C87.2918 49.3737 87.2918 48.957 86.8752 48.5404Z"
                            fill={`${userType === "coach"?"#F46C3D":"#24E09C"}`}
                        />
                        <path
                            d="M54.3752 71.457C53.3335 71.457 52.5002 72.2904 52.5002 73.332V81.2487C52.5002 82.4987 51.4585 83.5404 50.2085 83.5404H18.7502C17.5002 83.5404 16.4585 82.4987 16.4585 81.2487V18.7487C16.4585 17.4987 17.5002 16.457 18.7502 16.457H50.4168C51.6668 16.457 52.7085 17.4987 52.7085 18.7487V26.8737C52.7085 27.9154 53.5418 28.7487 54.5835 28.7487C55.6252 28.7487 56.4585 27.9154 56.4585 26.8737V18.7487C56.4585 15.4154 53.7502 12.707 50.4168 12.707H18.7502C15.4168 12.707 12.7085 15.4154 12.7085 18.7487V81.2487C12.7085 84.582 15.4168 87.2904 18.7502 87.2904H50.4168C53.7502 87.2904 56.4585 84.582 56.4585 81.2487V73.332C56.2502 72.2904 55.4168 71.457 54.3752 71.457Z"
                            fill={`${userType === "coach"?"#F46C3D":"#24E09C"}`}
                        />
                        </svg>
                    </StyledImgBox>
                </Grid>
                    <Grid container justifyContent="center" className="logoutText">
                        <StyledHeadingBox>Are you sure want to logout ?</StyledHeadingBox>
                    </Grid>
                </Grid>
                <Grid item md={12} container spacing={3} justifyContent="center" className="allButton">
                    <Grid item md={6}>
                        <ModalBodyCancel data-test-id="closeLogoutModal" onClick={logoutModalClose}>Cancel</ModalBodyCancel>
                    </Grid>
                    <Grid item md={6}>
                        <ModalBodyLogout style={{backgroundColor:`${userType === "coach"?"#F46C3D":"#24E09C"}`}} data-test-id="logoutUser" onClick={logoutUserData}  >Logout</ModalBodyLogout>
                    </Grid>
                </Grid>
            </ModalBody>
        </Modal>
    )
}

export default LogoutModal;

const ModalBody = styled(Box)({
    position: "relative",
    top: "50%",
    left: "50%",
    cursor: "poniter",
    fontFamily: "Open Sans",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: "40px",
    padding: "44px 30px",
    outline: "none",
    boxSizing: "border-box",
    "& .exitLogoutStyle": {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '50px'
    },
    "& .logoutText": {
        marginTop: "30px"
    },
    "& .allButton": {
        marginTop: "30px"
    },
    "& .modalTitle": {
        color: "#390689",
        fontSize: "22px",
        fontFamily: "Martian Mono",
        fontWeight: 700,
        lineHeight: "22px",
        textAlign: "center",
        borderBottom: "1px solid #CBD5E1",
        paddingBottom: "30px"
    }, "@media (max-width: 600px)": {
        maxWidth: "385px"
    },
    "@media (max-width: 400px)": {
        maxWidth: "385px",
        width: "85%"
    },
});
const StyledImgBox = styled(Box)({
    display: 'flex',
    height: '100px',
    width: '100px',
    justifyContent: "center",
});
const StyledHeadingBox = styled(Box)({
    color: "#390689",
    fontSize: "18px",
    fontWeight: 400,
    display: 'flex',
    justifyContent: "center",
    lineHeight: "22px",
    marginBottom: "4px",
    fontFamily: "Open Sans"
});
const ModalBodyCancel = styled("button")({
    backgroundColor: "#F1F5F9",
    fontFamily: "Open Sans",
    height: "44px",
    color: "#64748B",
    alignSelf: "flex-end",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight:"24px",
    textTransform: "capitalize",
    borderRadius: "30px",
    width: "200px",
    outline: 'none',
    border: 'none',
    cursor: "pointer",
    boxSizing: "border-box",
    "&:hover, &:focus": {
        color: "#64748B !important"
    }
});
const ModalBodyLogout = styled("button")({
    fontFamily: "Open Sans",
    backgroundColor: "#F46C3D",
    height: "44px",
    color: "#fff",
    fontSize: "18px",
    lineHeight:"24px",
    fontWeight: 700,
    outline: 'none',
    border: 'none',
    textTransform: "capitalize",
    borderRadius: "30px",
    width: "200px",
    cursor: "pointer",
    boxSizing: "border-box"
});
