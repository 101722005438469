import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '../../blocks/languageoptions/src/languageComponent/translationFiles/en/translation.json';
import translationAR from '../../blocks/languageoptions/src/languageComponent/translationFiles/ar/translation.json';

i18n.use(initReactI18next).init({
  lng: localStorage.getItem('language') || 'en',
  fallbackLng: localStorage.getItem('language') || 'en',
  resources: {
    en: {
      translation: translationEN,
    },
    ar: {
      translation: translationAR,
    },
  },
  interpolation: { escapeValue: false },
});

export default i18n;