import { v4 as uuidv4 } from 'uuid';
import { runEngine } from '../../../framework/src/RunEngine';
import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum';

export default class CoachGoalDetailAdapter {
  send: (message: Message) => void;

  constructor() {
    const blockId = uuidv4();
    this.send = message => runEngine.sendMessage(blockId, message);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.CoachGoalViewDetails)
    ]);
  }

  convert = async (from: Message): Promise<Message> => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'CoachGoalDetail');

    message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        from.getData(getName(MessageEnum.NavigationPropsMessage))
      );

      const raiseMessage = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
   
      raiseMessage.addData(
        getName(MessageEnum.CoachGoalDetailID),
        from.getData(getName(MessageEnum.CoachGoalDetailID))
      );

      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage)
    return message;
  };

  async receive(from: string, message: Message) {
    this.send(await this.convert(message));
  }
}
