import React from 'react';
import { styled } from '@material-ui/core';

const Progressbar = () => {
    return (
        <Wrapper>
            <div id="bar" className="bar-2 animating-2" />
        </Wrapper>
    )
}

export default Progressbar;


const Wrapper = styled('div')({
    backgroundColor: "#f1f1f1",
    height: "5px",
    width: "100%",
    position: "relative",
    '& .bar-2': {
        background: '-webkit-gradient(linear, left top, right top, color-stop(0%,#5F0AE5), color-stop(100%,#390689))',
        height: 'auto',
        minHeight: '5px',
        width: '0%',
    },

    '& .animating-2': {
        ' -webkit-animation': 'progress 5s infinite linear',
    }
});